@import '~qw-ui-lib-v2/src/components/_settings/prefab.scss';
@import '~qw-ui-lib-v2/src/components/_settings/workbench.scss';
@import '~qw-ui-lib-v2/build/index.css';

.rightPanelWrapper .rightPanelContainer .panelChildWrapper {
  padding: 0;
}

.mainLayout {
  width: 100%;
  height: 100%;
  min-height: 90vh;
}

.rightPanelWrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
}
