@import 'qw-theme';

* {
  font-family: @qw-font-family-stack !important;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
p {
  margin-bottom: 0 !important;
}
ul,
ol {
  list-style: none;
}

// .ant-layout {
//   background: white !important;
// }

.ant-menu-submenu-horizontal > .ant-menu {
  margin-top: 0px;
}
.ant-card-body {
  padding: 14px !important;
}
.ant-menu-item-active,
.ant-menu-item-selected,
.ant-menu-submenu-active {
  border-bottom: @qw-border-width-small solid @qw-color-white !important;
}

.ant-modal {
  @media screen and (max-width: 991px) {
    width: 100% !important;
    height: 100% !important;
    margin: 0px;
    padding-bottom: 0px;
  }
}

.ant-modal-content {
  border-radius: 0;
  @media screen and(max-width: 991px) {
    width: 100%;
    height: 101% !important;
    overflow: auto;
  }
}

.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0 15px;
}
