@import './style/qw-theme';

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.page-container .ant-table-body {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.unscrollable {
  position: fixed;
}

.page-container .pagination .ant-pagination {
  font-size: 0.75rem;
}

.page-container .pagination .ant-pagination > li {
  height: 1.5rem;
  line-height: 1.5rem;
  min-width: 1.5rem;
}

.page-container .pagination .ant-pagination > li:not(:last-child) {
  margin-right: 0.3rem;
}

.page-container .pagination .ant-pagination-prev .ant-pagination-item-link:after,
.page-container .pagination .ant-pagination-next .ant-pagination-item-link:after {
  height: 1.375rem;
  line-height: 1.375rem;
  min-width: 1.375rem;
}

.page-container .pagination .ant-pagination-item-active {
  background-color: #084782;
  border-color: #084782;
  height: 24px;
  width: 28px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.theatres-page-overview-layout,
.theatres-page-overview-layout .theatre-page-selection-side:nth-child(1) {
  padding: 0 8px 0 0 !important;
  margin-top: @qw-spacing-x-small;
  .qw-search {
    width: 100%;
  }
}
.theatres-page-overview-layout .theatre-page-selection-side:nth-child(2) {
  padding: 0 0 0 8px !important;
  margin-top: @qw-spacing-x-small;
}
.page-container{
  margin:24px 0px 48px !important;
}